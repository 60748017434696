<template>
            <th>      <slot></slot>    
                        <b class="border rounded-circle bg-info text-white m-0">&nbsp;{{res[0]}}&nbsp;</b>
                        <b class="border rounded-circle bg-info text-white">&nbsp;{{res[1]}}&nbsp;</b>
                        <b class="border rounded-circle bg-danger text-white">&nbsp;{{res[2]}}&nbsp;</b>
                        <b class="border rounded-circle bg-danger text-white">&nbsp;{{res[3]}}&nbsp;</b>
                        <b class="border rounded-circle bg-danger text-white">&nbsp;{{res[4]}}&nbsp;</b>
                              
                              
            </th>
</template>
<script>
import axios from 'axios';
export default {
    props: ["res"],
    name: "Result",

    // data(){
    //     return{
    //         limiter: 0,
    //         switch_btn: "",
    //         checked:  true,
          
    //         result1: "",
    //         result: ""
           
    //         }
    // },
}

</script>

<style>

</style>