<template>
            <th style="color: #e3342f;"> <slot></slot>         
                <b class="border rounded-circle bg-info text-white m-0"  
                style="background-color: #ad201c !important;border-bottom: 2px solid #ffe817 !important;">&nbsp;{{result1[0]}}&nbsp;</b>
                <b class="border rounded-circle bg-info text-white"
                style="background-color: #ad201c !important;border-bottom: 2px solid #ffe817 !important;">&nbsp;{{result1[1]}}&nbsp;</b>
                <b class="border rounded-circle bg-danger text-white"
                style="background-color: #e3342f  !important;border-bottom: 2px solid #ffe817 !important;">&nbsp;{{result1[2]}}&nbsp;</b>
                <b class="border rounded-circle bg-danger text-white"
                style="background-color: #e3342f  !important;border-bottom: 2px solid #ffe817 !important;">&nbsp;{{result1[3]}}&nbsp;</b>
                <b class="border rounded-circle bg-danger text-white"
                style="background-color: #e3342f  !important;border-bottom: 2px solid #ffe817 !important;">&nbsp;{{result1[4]}}&nbsp;</b>      
            </th>
</template>
<script>
import axios from 'axios';
export default {
    props: ["link","id"],
    name: "Admin",
    data(){
        return{
            limiter: 0,
            switch_btn: "",
            checked:  true,         
            result1: "",
            result: ""           
            }
    },
    methods: {
        fire(){
            if(this.checked){
                if( this.limiter==0){
                     this.switch_btn =setInterval(()=>{
                            this.result1=  ""+Math.ceil( Math.random()*(99999-10000)+10000);
                                },80)
                    this.limiter++;     
            }
            }else{
                axios.get('http://localhost:8000/get_data')
                .then((res)=>{
                })
                // .catch((e)=>{
                //     console.log(e)
                //     })
            clearInterval(this.switch_btn);
            }
        },
        switch_btn_fn(){
            clearInterval(this.switch_btn);
            his.limiter=0;
        }    
    },
    created(){
        this.fire();
    }
}
</script>
